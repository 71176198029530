import { Link } from "gatsby"
import React from "react"

import "./header.scss"

const Header = () => (
  <header className="main-header">
      <h1>
        <Link to="/">Nicole Heiniger</Link>
      </h1>

      <nav className="main-header__nav">
        <Link to="/archive">Archive</Link>
        <Link to="/contact">Contact</Link>
      </nav>
  </header>
)

export default Header
